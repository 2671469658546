import { RoleTypes } from "@/helpers/roleTypes";

export const QuestionnaireMapByProjectStatus = {
  "pending": ["pos-dg", "odase", "odase-express", "values-personal"],
  "ready": ["pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier", "odase", "odase-express", "values-personal"],
  "ongoing": [],
  "completed": [],
  "archived": [],
  "closed": [],
};

export const QuestionnaireMapByVia = {
  "email": ["pos-dg", "pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier", "odase", "odase-express", "values-personal"],
  "phone": ["pos-dg", "pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier"],
};

export const QuestionnaireUserRoleMap = {
  "pos-dg": [RoleTypes["Client-Admin"]],
  "pos-dg-2": [RoleTypes["Client-Admin"]],
  "pos-staff": [RoleTypes["Client-Staff"]],
  "pos-staff-2": [RoleTypes["Client-Staff"]],
  "pos-consumer": [RoleTypes["Client-Consumer"]],
  "pos-consumer-2": [RoleTypes["Client-Consumer"]],
  "pos-consumer-3": [RoleTypes["Client-Consumer"]],
  "pos-consumer-4": [RoleTypes["Client-Consumer"]],
  "pos-distributor": [RoleTypes["Client-Consumer"]],
  "pos-retailer": [RoleTypes["Client-Consumer"]],
  "pos-supplier": [RoleTypes["Client-Consumer"]],
  "odase": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
  "odase-express": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
  "values-personal": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
};