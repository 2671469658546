import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure,
} from "vee-validate";
import { required, email, confirmed, min, numeric } from "@vee-validate/rules";
import { app } from '/src/main.js'

function getTranslation(value) {
  // return the translated value from i18n
  return app.config.globalProperties.$t(value)
}

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    defineRule("email", email);
    defineRule("confirmed", confirmed);
    defineRule("min", min);
    defineRule("numeric", numeric);

    configure({
      generateMessage: (ctx) => {
        const { t } = app.config.globalProperties.$i18n; 

        const messages = {
          required: getTranslation("validations.required"),
          email: getTranslation("validations.email"),
          confirmed: getTranslation("validations.passwordsNotMatch"),
          min: getTranslation("validations.pw6chars"),
          numeric: getTranslation("validations.numbersOnly"),
        };

        const message = messages[ctx.rule?.name]
          ? messages[ctx.rule?.name]
          :  getTranslation("validations.default", { field: ctx.field });

        return message;
      },
      validateOnBlur: false,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });
  },
};
